<template>
   <div class="inner_banner"><a class="db" href="javascript:void(0)"><img :src="require('@/assets/images/member.png')"/></a></div>


    <main class="member_center auto">
      <ul class="member_list">
        <li>
          <img :src="require('@/assets/images/member_1.png')" class="db img">
          <div class="member_info abs auto">
            <h2 class="tc">普通会员</h2>
            <h3>· 免费下载10条招投标信息</h3>
            <h3>· 免费下载10条文库信息</h3>
          </div>

        </li>

        <li v-for="(item,index) in allMembers" :key="item.id">
          <img :src="require(`@/assets/images/member_${index+2}.png`)" class="db img">
          <div class="member_info abs auto">
            <h2 class="tc">{{item.name}}</h2>
            <h3>· 文库{{item.library}}条/<h3 class="dib" v-if="item.name==='年会员'">年</h3><h3 class="dib" v-else>月</h3></h3>
            <span>下载{{item.library}}条，超过下载上限，按条收费付款</span>
            <h3>· 招投标数据{{item.bidding}}条/<h3 class="dib" v-if="item.name==='年会员'">年</h3><h3 class="dib" v-else>月</h3></h3>
            <span>下载{{item.bidding}}条，超过下载上限，付费1元/条</span>
            <button v-if="item.id===1" class="db auto btn" :disabled="memberLevel>=1" :class="[memberLevel>=1?'disable':'']"  @click="buyMember(item.name)">购买</button>

                   <button v-if="item.id===2" class="db auto btn" v-show="memberLevel!=1" :disabled="memberLevel!==0" :class="[memberLevel!==0?'disable':'']" @click="buyMember(item.name)">购买</button>
            <button v-if="item.id===2" v-show="memberLevel==1" class="db auto btn" :disabled="memberLevel!==1" :class="[memberLevel!==1?'disable':'']" @click="buyMember('升级')">升级</button>
            <button v-if="item.id===3" class="db auto btn" :disabled="memberLevel!==0" :class="[memberLevel!==0?'disable':'']"  @click="buyMember(item.name)">购买</button>

            
            
            <span class="tc dib w100p price_1">{{item.cost}}元/<span class="dib price_1" v-if="item.name==='年会员'">年</span><span class="dib price_1"  v-else>月</span></span>
          </div>
        </li>
      </ul>

      <table>
        <tr class="heading">
          <td>普通会员</td>
          <td>高级会员</td>
          <td>超级会员</td>
          <td>年会员</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          
          <td v-for="(item,index) in allMembers" :key="item.id">{{item.cost}}元/<span v-if="item.name==='年会员'">年</span><span v-else>月</span><br/>
          下载{{item.bidding}}条，超过下载上限，付费1元/条
          </td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>获取医信招投标、邀标、询价、竞谈、单一、竞价等招标采购信息服务获取包括项目名称在内公告内容等信息；</td>
          <td>获取医信招投标、邀价、询价、竞谈、单一、竞价等招标采购信息服务、获取包括项目名称在内公告内容等信息；</td>
          <td>获取医信招投标、邀价、询价、竞谈、单一、竞价等招标采购信息服务、获取包括项目名称在内公告内容等信息；</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>项目动态：包含招标日期、采购单位、项目类别、金额等；</td>
          <td>项目动态；包含招标日期、截止日期、采购单位、项目类别、金额等；</td>
          <td>项目动态；包含招标日期、截止日期、采购单位、项目类别、金额等；</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>可编辑电子表格；</td>
          <td>可编辑电子表格；</td>
          <td>可编辑电子表格；</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>提供中标单位名称信息。</td>
          <td>可视化、可操作公告原地址链接；</td>
          <td>可视化、可操作公告原地址链接；</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>细化公告项目内容、具化公告时间；</td>
          <td>细化公告项目内容、具化公告时间；</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>提供中标单位名称信息；</td>
          <td>提供中标单位名称信息；</td>
        </tr>
        <tr>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td class="tc"><i class="iconfont icon-false"></i></td>
          <td>将为购买一年以上的会员定制版用户特别赠送媒体推广服务礼包：即自购买日起1个自然年内，免费为用户提供医信邦官方网站（http://www.hitzone.cn/）1次广告推送（展示位置：医信邦官网首页“推荐”板块，以图文方式呈现，展示时间为7个工作日）；医信邦公众号（公众号名称：医信邦；微信号：hitzone-cn）次条版面（价值4000元）1次广告推送（注：公众号头次条广告推送时间需间隔30个工作日）。广告推广具体事宜由双方另行协商确定，协商不成的，视作乙方自愿放弃该权益。</td>
          <td>将为购买一年以上的会员定制版用户特别赠送媒体推广服务礼包：即自购买日起1个自然年内，免费为用户提供医信邦官方网站（http://www.hitzone.cn/）1次广告推送（展示位置：医信邦官网首页“推荐”板块，以图文方式呈现，展示时间为7个工作日）；医信邦公众号（公众号名称：医信邦；微信号：hitzone-cn）头条版面（价值8000元）1次广告推送、次条版面（价值4000元）1次广告推送（注：公众号头次条广告推送时间需间隔30个工作日）。广告推广具体事宜由双方另行协商确定，协商不成的，视作乙方自愿放弃该权益。</td>
        </tr>
      </table>
      
    </main>

</template>
<script setup>
  import {ref,computed,watchEffect,onMounted} from "vue"
  import $api from "@/api"
  import {useStore} from "vuex"
  const store=useStore()
  const isLogin=computed(()=>!!store.state.userState.token)

  const memberLevel=computed(()=>store.state.userState.level)
  const allMembers=ref()
  const getAllMembers=async()=>{
    const [err,data]=await $api.user.getMemberClassies()
    if(!data.succeeded)return;
    allMembers.value=data.data.items.sort((a,b)=>a.id-b.id)
  }
  const buyMember=async(type)=>{
    if(!isLogin.value){
      store.commit("toggleLoginForm",true)
    }else{
      store.commit("setOrderInfo",{title:"becomeMember",id:0,price:999,type:4})
          store.commit("toggleOrdinaryForm",false)
          switch (type) {
            case '高级会员':
              store.commit("toggleBecomeMemberForm",true)
              break;
            case '升级':
              store.commit("toggleUpdateMemberForm",true)
              break;
            case '超级会员':
               sessionStorage.setItem('memberType',type)
              store.commit("toggleBecomeMemberForm",true)
              break;
            default:
              sessionStorage.setItem('memberType',type)
              store.commit("toggleBecomeMemberForm",true)
              break;
          }
    }
  }
  onMounted(getAllMembers)
</script>
<style lang="less" scoped>
// .img{
//   width: 350px;
// }
 .member_list li {position:relative;box-shadow: -5px 0px 28px 4px rgba(116,122,129,0.25);}
      // .member_list li img{width: 350px;}
      .member_info{width: 250px;top:60px;left:140px;margin-left: -125px;color:#fff;}
      .member_info h2{font-size: 30px;margin-bottom: 30px;font-weight: 800;}
      .member_info p,h3{font-size: 18px;margin-bottom: 10px;}
      .member_info span{opacity: 0.6;margin-left: 10px;}
      .member_info h3{margin-bottom: 0px;margin-top: 10px}
      .member_info a{color:#fff;font-size:18px;height: 24px;line-height:24px;width: 70px;border:1px solid #fff;border-radius: 5px;text-align: center;margin:20px auto 5px;}
      .member_info .price_1{color:#604106;}
      .member_info .price_2{color:#A7BCFA;}
      .disable{
        background: #919191 !important;
        border: 1px solid #919191 !important;
        color: #545454 !important;
        cursor: not-allowed !important;
      }
      .btn{
        cursor: pointer;
    width: 70px;
    height: 24px;
    background: transparent;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    font-size: 18px;
    text-align: center;
    line-height: 12px;
    margin: 20px auto 5px;
      }

  // 2021 11 08
  .heading td:first-child,.heading td:nth-child(2),.heading td:nth-child(3){width:300px;}
  .member_info .price_1{color:#fff;margin-left:0;}
  .price_1 .price_1{opacity: 1;}
</style>